@import "../../../_metronic/_assets/sass/themes/layout/brand/dark.scss";

.services-paywall {
    margin: 0 auto;
    max-width: 500px;
    text-align: center;    

    &__header {
        margin: 0 0 32px;

        &__image {
            width: 100%;
        }

        &__title,
        &__caption {
            font-weight: 600;
            margin: 0 50px;
        }

        &__title {
            color: #000000;
            font-size: 26px;
            margin-bottom: 16px;
        }

        &__caption {
            color: #777777;
            font-size: 12px;
        }
    }

    &__benefits {
        list-style: none;
        margin: 0 auto;
        padding: 0;
        text-align: left;
        width: 110px;

        &__item {
            color: $brand-bg;
            font-weight: 600;

            &__tick {
                margin-right: 8px;
            }
        }
    }

    &__line {
        border: 0px solid #DFDFDF;
        border-bottom-width: 1px;
        margin: 40px 0;
    }

    &__subscription {
        &__special {
            display: block;
            font-weight: 600;
            margin: 0 0 20px;
        }

        &__compare {
            display: block;
            font-size: 16px;
            margin: 20px 0;
        }

        &__price {
            margin: 20px 0 0;
            font-size: 72px;
            font-weight: 300;

            &__interval {
                font-size: 12px;
                font-weight: 600;
            }

            &__mid {
                font-size: 24px;
                font-weight: 600;
            }
        }

        &__button {
            font-weight: 500;
            margin: 30px 0;

            &__arrow {
                margin: 0 0 0 8px;
            }
        }
    }
}