.select {
  &__control {
		background-color: $input-solid-bg;
		border-color: $input-solid-bg;
    border-radius: 0.42rem;
		color: $input-solid-color;
    font-size: 1.08rem;
    height: auto;
    line-height: 1.5;
    padding: 0.425rem 0.52rem;
    transition: $transition-input;
  }

  &__option {
    padding: 0.825rem 1.42rem !important;

    &--is-selected {
      background-color: $brand-bg !important;
    }

    &--is-focused {
      background-color: #eaedf2 !important;
      color: inherit !important;
    }
  }

  &__multi-value {
    background-color: #eaedf2 !important;

    &__remove {
      &:hover {
        background-color: $danger-hover !important;
        color: $danger-inverse !important;
      }
    }
  }

  &__menu {
    z-index: 100 !important;
  }
}
