.featured-row {
    &__item {
        position: relative;

        &__remove {
            background: $danger;
            border-radius: 50%;
            box-sizing: border-box;
            color: white;
            cursor: pointer;
            font-size: 80%;
            font-weight: bold;
            height: 20px;
            padding: 6px 0 0 1px;
            position: absolute;
            right: -7px;
            text-align: center;
            top: -7px;
            width: 20px;
            z-index: 2;
        }

        &__inner {
            border: 1px dashed #eee;
            border-radius: 3px;
            height: 130px;
            margin: 8px;
            z-index: 1;

            &__add {
                cursor: pointer;
                height: 100%;
                position: relative;

                &__icon {
                    font-size: 30px;
                    left: 50%;
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);    
                }
            }

            &__item {
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: center;
                text-align: center;

                &__image {
                    &__image {
                        background-size: cover;
                        box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
                        height: 50px;
                        border-radius: 50%;
                        margin: 0 auto 10px;
                        width: 50px;    
                    }
                }

                &__name {
                    overflow: hidden;
                    padding: 0 10px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
}
