.review-star {
  color: #ececee;
  cursor: pointer;
  font-size: 32px;
  margin-right: 4px;
  transition: color 0.3s;

  &--active {
    color: #fcde02;
  }
}