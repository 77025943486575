@import "../../../_metronic/_assets/sass/themes/layout/brand/dark.scss";

.nv-toggle {
    color: #777777;
    display: flex;
    justify-content: center;
    text-align: left;

    &__switch {
        cursor: pointer;
        margin: 0 8px;

        &__control {
            background: rgba(0, 119, 175, 0.1);
            border-radius: 10px;
            display: inline-block;
            height: 20px;
            width: 50px;

            &__ball {
                background: $brand-bg;
                border-radius: 10px;
                display: inline-block;
                height: 20px;
                width: 20px;
            }
        }


        > input {
            display: none;

            &:checked ~ .nv-toggle__switch__control {
                text-align: right;
            }
        }
    }
}
